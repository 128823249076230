body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.tox-statusbar__branding svg {
  display:none;
}

.general{
  padding-top: 5px;
  width: 100%;
  height: 1170px;
  font-size: 15px;
  background-color: #525669;
  padding-bottom: 15px;
}

.fm .group{
  width: 1060px;
  height: auto;
  margin: 0 auto;
  background-color: #eeeeee;
  height: 410px;
}
.t1, .t2, .t3, .t4{
  border-collapse: collapse;
  table-layout: fixed;

}
.t1 td{
  padding: 0px;
  border: 1px solid #000000;
  height: 40px;  
}
.t1 .oddcol{
  padding-left: 5px;
  width: 200px;
  background-color: #CCCCCC;
  font-weight: bolder;
}
.t1 p{
  margin-top:5px;
  width: 100%;
  height: 40px;
  border: none;
  resize: none;
  outline: 0px none transparent;
  font-size: 15px;
  background-color:#F1F4FF;
  
}
.t1 p:focus{
  margin-top:5px;
  margin-left:0px;
  width: 100%;
  height: 40px;
  border: none;
  resize: none;
  outline: 1px dotted black;
  font-size: 15px;
  background-color:#ffffff;

}
.t4 .first p{
  height:66px;
  border: none;
  resize: none;
  background-color:#F1F4FF;
  width:210px
}
.fstp{
  height:80px;
  background-color:#F1F4FF;
  width: 188px;
  word-wrap: break-word;   
  overflow-wrap: break-word;
  word-break: break-word;
}
.t4 .fst p{
  width: 198px;
  border: none;
  resize: none;
  outline: 0px none transparent;
  background-color:#F1F4FF;
}
.t4 .fst p:focus{
  border: none;
  resize: none;
  outline: 1px dotted black;
  font-size: 14px;
  background-color:#ffffff;
  width:198px;
}
.t4 .first p:focus{
  border: none;
  resize: none;
  outline: 1px dotted black;
  font-size: 14px;
  background-color:#ffffff;

}
.t1 .evencol{
  padding-left: 5px;
  width: 350px;
  column-width: 350px;
  background-color: #F1F4FF;
}
.t2, .subH3{
  margin-left: 25px;
  font-size: 17px;
  margin-top: 5px;
  font-weight: bold;
}
.t1,.t2{
  border-collapse: collapse;
}
.t2 td .t2td1, .tdothers {
  padding: 0px;
  border: 1px solid #000000;
  height: 15px;
  padding-bottom: 5px;
}
.t2 .hd11{
  background-color: #444444;
  border: 1px solid #000000;
  height: 15px;
  color: #ffffff;
  text-align: center;
  padding-bottom: 3px;
}

.red{
  background-color: red;
  color: #ffffff;
}
.orange{
  background-color: orange;
  color: #ffffff;
}
.green{
  background-color: green;
  color: #ffffff;
}
.ocenter{
  text-align: center;
  width: 130px;
}
.t2 .hd2{
  width: 110px;
  height: 15px;
  text-align: center;
  background-color: #CCCCCC;
  border: 1px solid #000000;
  padding-bottom: 3px;
}
.t2 .td2{
  width: 110px;
  height: 15px;
  text-align: right;
  background-color: #CCCCCC;
  border: 1px solid #000000;
  padding-bottom: 3px;
  padding-right: 8px;
}
.t2 .sideTitle{
  width: 35px;
  background-color: #444444;
  color: #ffffff;
}
.t2 .sideTitle .vText{
  transform: translate(25px, 51px) rotate(-90deg);
  width: 35px;
  margin-left: -25px;
  margin-bottom: 30px;

}
.t2Div{
  position: relative;
  float: left;
}
.t3Div{
  position: relative;
  float: left;
  margin-left: 60px;
  margin-top: 60px;
}
.t3{
  text-align: center;
}

.t3 td{
  text-align: center;
  border: 1px solid #000000;
  padding: 0px;
  height: 30px;
  width: 150px;
  color: #ffffff;
  font-size: 15px;
}
.thed{
  background-color: #444444;
}
.d4{
  width: 100%;
  margin: 0 auto;
  margin-top: -5px;
  background-color: #ffffff;
  padding-bottom: 20px;
}
.t4{
  margin-left: 22px;
}
.t4b{
  margin-top: 40px;
}
.t4 thead th{
  padding: 0px;
  border: 1px solid #000000;
  height: 89px;
  background-color: #cccccc;
  width: 210px;
}
.t4 thead th .top{
  margin: 0 auto;
  width: 210px;
  height: 20px;
  font-size: 15px;
  font-weight: bolder;
  text-align: center;
  display: block;
  margin-top: -30px;
}
.t4 thead th .bottom{
  margin: 0 auto;
  width: 210px;
  height: 20px;
  font-size: 8px;
  text-align: center;
  display: block;
  margin: -8px;
  color: #8A909A;
}
.t4 tbody tr td{
  border: 1px solid #000000;
  padding: 0px;
  width: 210px;
  background-color:#F1F4FF;
  height: 80px;
}
.t4 .sright p{
  margin-top: 1px;
  width: 500px;
  height: 80px;
  border: none;
  resize: none;
  outline: 0px none transparent;
  font-size: 15px;
  background-color:#F1F4FF;
  float: left;
  word-wrap: break-word;   
  overflow-wrap: break-word;
  word-break: break-word;
}

.first{
  height: 50px;
  width: 188px;
  background-color: #F1F4FF;
  color: #000000;
  word-wrap: break-word;   
  overflow-wrap: break-word;
  word-break: break-word;
}
.second{
  width: 100%;
  background-color:#F1F4FF;
  height: 100%;
}
.third{
  font-size: 10px;
}
.label{
  background-color: #ffffff;
  width: 80px;
  height: 40px;
  display: block;
  float: left;
  color: #525669;
  margin: 0px;
}
.date{
  background-color: #F1F4FF;
  width: 150px;
  height: 40px;
  display: block;
  float: left;
  border: none;
  resize: none;
  outline: 0px none transparent;
  margin: 0px;
  padding-top: 10px;
}
.date:focus{
  border: none;
  resize: none;
  outline: 1px dotted black;
  font-size: 15px;
  background-color:#ffffff;
}
.content{
  background-color: #F1F4FF;
  width: 150px;
  height: 40px;
  display: block;
  float: left;
  border: none;
  resize: none;
  outline: 0px none transparent;
  margin: 0px;
  padding-top: 10px;
}
.content:focus{
  border: none;
  resize: none;
  outline: 1px dotted black;
  font-size: 15px;
  background-color:#ffffff;
}
.sright p:focus{
  border: none;
  resize: none;
  outline: 1px dotted black;
  font-size: 15px;
  background-color:#ffffff;
}
.sleft, .sright{
  float: left;
}
.sright p{
  position: relative;
  width: 500px;
  height: 40px;
}
.sright{
  position: relative;
  width: 500px;
  height: 40px;
}
.sleft{
  height: 40px;
  position: relative;
  width: 130px;
  background-color: #ffffff;
  color: #525669;
  margin: 0px;
  padding-left: 10px;
}
.sleft p{
  height: 40px;
  position: relative;
  width: 130px;
  background-color: #ffffff;
  color: #525669;
  margin: 0px;
  padding-left: 10px;
}
.tTop{
  margin: 0 auto;
}
.ptop{
  margin-top: 20px;
}
.pbottom{
  margin-bottom: 25px;
  margin-top: 1px;
}
.fm{
  width: 1100px;
  background-color: #ffffff;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 800px;
  margin-top: 20px;
}
.fm1{
  height: 980px;
  margin-top: 100px;
}
.fm2{
  height: 980px;
  margin-top: 80px;
}
.d1{
  margin-bottom: 20px;
}
.d0{
  width: 100%;
  height: 50px;
}
.d2, .d3{
width: 100%;
margin: 0 auto;
height: auto;
}
.d2 .t2{
  padding-bottom: 50px;
}
.title, .logo{
  height: 35px;
  padding-top: 6px;
  padding-right: 15px;
  padding-bottom: 3px;
}
.title{
  width: 45%;
  text-align: right;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #444444;
  color:#ffffff;
  margin-right: 10px;
  font-weight: bolder;
}
.logo{
  background-color: #ffffff;
}
p{
  padding-left: 5px;
  padding-right: 5px;
  font-size: 12px;margin-left: 5px;
}
.calender{
  border: none;
  outline: 0px none transparent;
  font-size: 4px;
  width: 125px;
  height: 33px;
  background-color: #F1F4FF !important;
  padding-left: 5px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 13px !important;
}
.calender:focus{
  border: none;
  outline: 0px none transparent;
  font-size: 4px;
  width: 125px;
  height: 33px;
  background-color: #ffffff !important;

}
.sigCanvas {
  width: 100%;
  border: 1px solid black;
}

.sigImage {
  top: 10px;
  display: block;
  max-width: 50%;
  /* max-height: 50px; */
  margin: 0 auto;
  border: 1px solid black;
  padding: 2rem;
}
.floatButton4, .floatButton3, .floatButton2, .floatButton1{
	position:fixed;
	width:100px;
	height:60px;
	right:10px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50%;
	text-align:center;
	box-shadow: 2px 2px 3px #999;

}

.floatButton4{
	bottom:220px;
}
.floatButton3{
	bottom:160px;
}
.floatButton1{
	bottom:100px;
}
.floatButton2{
	bottom:40px;
}
.signature {
  width: 65%;
  height: 65%
  
}
